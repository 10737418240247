import React from 'react';
import { Box, Link, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import useTranslate from '../../../../hooks/useTranslate';

const Comment = ({ comment }) => {
  const { translate, translation, isTranslating } = useTranslate();
  const isPatient = comment.authorable_type.includes('EConsult');
  return (
    <Box mt={2}>
      {translation ? (
        <Typography>{`Translation : ${translation}`}</Typography>
      ) : (
        <Typography>{comment.content}</Typography>
      )}
      <Typography variant="caption" color="textSecondary">
        {isPatient && 'Patient - '}
        {format(comment.created_at, 'dd/MM - HH:mm')}
        {!translation && isPatient && (
          <Box component="span" ml={1}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                translate(comment.content);
              }}
            >
              {isTranslating ? 'Translating...' : 'Translate'}
            </Link>
          </Box>
        )}
      </Typography>
    </Box>
  );
};

export default Comment;
